import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {ProductListSkeleton} from "components/product-list-item/ProductListSkeleton";
import {getBlLocaleFromString} from "lib/BlLocale";
import {getAllProducts} from "lib/api/productApi";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import {fromPairs, keys} from "ramda";
import {ProductCard} from "components/product-card/ProductCard";

const targets = Array.from(document.getElementsByClassName('product-standalone'))
    .filter(el => el instanceof HTMLElement)
    .map(el => el as HTMLElement)
    .filter(el => el.dataset['productId']);

function displaySkeletons(targets: HTMLElement[]) {
    targets.forEach(el => ReactDOM.render(<React.StrictMode><ProductListSkeleton/></React.StrictMode>, el));
}

function fetchAndDisplayProducts(targets: HTMLElement[]) {
    const elementsByProductId = fromPairs(targets.map(el => [el.dataset['productId']!, el]));
    const productIds = keys(elementsByProductId);
    getAllProducts(JSON.stringify(productIds))
        .then(products => products
            .map(p => ({
                product: p,
                element: elementsByProductId[p.productId]
            }))
            .filter(({element}) => element)
            .forEach(({product, element}) => {
                const rawLocaleCode = element.dataset['localeCode'];
                const localeCode = getBlLocaleFromString(rawLocaleCode);
                const blackFridayActive: boolean = element?.dataset?.['blackFridayActive'] === 'true';
                const rawOrderId = element?.dataset?.['orderId'];
                const orderId = rawOrderId != null ? parseInt(rawOrderId) : null;
                ReactDOM.render(
                    <BundleEntry
                        bundleName="product-standalone"
                        locale={localeCode}
                        prefetchTranslations={[
                            'price.onSaleInstead',
                            'product.unavailable',
                            'degupack.button.config.label',
                            'friendspack.button.config.label',
                            'adventcalendar.button.config.label',
                            'giftcard.button.config.label',
                            'frontend.favorites.add',
                            'frontend.favorites.remove',
                            'frontend.adventcalendar.select.design',
                            'frontend.adventcalendar.winter.edition',
                            'frontend.adventcalendar.winter.edition.beers',
                            'frontend.adventcalendar.winter.edition.description',
                            'frontend.adventcalendar.light.and.fruity.edition',
                            'frontend.adventcalendar.light.and.fruity.beers',
                            'frontend.adventcalendar.light.and.fruity.description',
                            'frontend.adventcalendar.select.design.button',
                            'frontend.adventcalendar.create',
                            'frontend.adventcalendar.merry.christmas.type',
                            'frontend.adventcalendar.merry.christmas.type.description',
                            'frontend.adventcalendar.best.dad.type',
                            'frontend.adventcalendar.best.dad.type.description',
                            'frontend.adventcalendar.best.friends.type',
                            'frontend.adventcalendar.best.friends.type.description',
                            'frontend.adventcalendar.back',
                            'frontend.adventcalendar.next',
                            'frontend.adventcalendar.summary',
                            'frontend.adventcalendar.summary.description',
                            'frontend.adventcalendar.gift.title',
                            'frontend.adventcalendar.recipient',
                            'frontend.adventcalendar.recipient.placeholder',
                            'frontend.adventcalendar.from.placeholder',
                            'frontend.adventcalendar.to.placeholder',
                            'frontend.adventcalendar.message.placeholder',
                            'frontend.adventcalendar.from',
                            'frontend.adventcalendar.to',
                            'frontend.adventcalendar.message',
                            'frontend.adventcalendar.gift.description',
                            'frontend.adventcalendar.daily.greetings',
                            'frontend.adventcalendar.yes.send.newsletter',
                            'frontend.adventcalendar.yes.send.newsletter.disclaimer',
                            'frontend.adventcalendar.no.send.newsletter',
                            'frontend.adventcalendar.add.to.cart',
                            'frontend.adventcalendar.message.description'
                        ]}>
                        <ProductCard
                            blackFridayActive={blackFridayActive}
                            orderId={orderId!}
                            mode="responsive"
                            product={product}
                        />
                    </BundleEntry>,
                    element);
            })
        )
}


displaySkeletons(targets);
fetchAndDisplayProducts(targets);
